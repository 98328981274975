import React, { FC, useEffect, useLayoutEffect, useRef, useState } from 'react'

import styles from './ReportModal.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../redux/store'
import {
  Expense,
  fetchAddReports,
  fetchCurrentReport,
  fetchReportFile,
  setIsVisibleModalReport,
} from '../../../redux/reducers/reports'
import classNames from 'classnames'
import { carsSelector } from '../../../redux/selectors/cars-selector'
import { reportsSelector } from '../../../redux/selectors/reports-selector'
import { getRole } from '../../../utils/getRole'

import { toast } from 'react-toastify'
import { IMAGE_TYPE } from '../../../constants/file-types'
import { fetchUsers } from '../../../redux/reducers/users'
import { driversAndAssistantsSelector } from '../../../redux/selectors/users-selector'
import DateTimePicker from '../../common/date-picker/DatePicker'
import { fetchCars } from '../../../redux/reducers/cars'
import { PhotoProvider, PhotoView } from 'react-photo-view'
import {
  fetchAddExpense,
  setCurrentExpenses,
  setVisibleDeleteModalExpenses,
} from '../../../redux/reducers/reports'
import { PATH } from '../../../constants/path'
import EditButton from '../../buttons/actions-button/EditButton'
import DeleteButton from '../../buttons/actions-button/DeleteButton'
import { useClearBodyNoScrollClass } from '../../../hooks/use-clear-body-no-scroll-slass'
import LoadingPage from '../../loading/LoadingPage'
type FileArray = File[]

const typePayment = {
  cash: 'Нал',
  cash_check: 'Нал (чек)',
  card: 'Карта',
  card_check: 'Карта (чек)',
  fuel_card: 'Топл. карта',
}

const Expenses: FC<{ expenses: Expense }> = ({ expenses }) => {
  const [isVisibleEditExpenses, setIsVisibleEditExpenses] = useState(false)
  const dispatch = useDispatch<AppDispatch>()
  const handleDeleteExpenses = (expenses: Expense) => {
    dispatch(setCurrentExpenses(expenses))
    dispatch(setVisibleDeleteModalExpenses(true))
  }

  const textareaRef = useRef(null)

  const [amountExpenses, setAmountExpenses] = useState(expenses?.amount)
  const [currencyExpenses, setCurrencyExpenses] = useState(expenses?.currency)
  const [dateExpenses, setDateExpenses] = useState(expenses?.date)
  const [descriptionExpenses, setDescriptionExpenses] = useState(
    expenses?.description
  )
  const [paymentTypeExpenses, setPaymentTypeExpenses] = useState(
    expenses.payment_type
  )
  const [quantityExpenses, setQuantityExpenses] = useState(expenses?.quantity)
  const [typeExpenses, setTypeExpenses] = useState(expenses.type)

  const [selectedPhoto, setSelectedPhoto] = useState<string[]>([])
  const [selectedPhotoForSend, setSelectedPhotoForSend] = useState<File[]>([])

  const handleCurrencyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setCurrencyExpenses(value)
  }

  const handlePhotoChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    // Проверка на null перед использованием Array.from
    if (event.target.files) {
      const files = event.target.files
      const maxSize = 50 * 1024 * 1024 // 50MB в байтах

      for (let i = 0; i < files.length; i++) {
        if (files[i].size > maxSize) {
          toast.error(
            `Файл ${files[i].name} превышает максимальный размер 50MB.`
          )
          return
        }
      }
      const filesArray: FileArray = Array.from(event.target.files)

      setSelectedPhotoForSend((prevPhotos: FileArray) => [
        ...prevPhotos,
        ...filesArray,
      ])

      const photosUrls: string[] = filesArray.map((file) =>
        URL.createObjectURL(file)
      )

      setSelectedPhoto((prevUrls: string[]) => [...prevUrls, ...photosUrls])
    }
  }

  const handleDeletePhoto = (photo: string) => {
    setSelectedPhoto((currentPhotos) =>
      currentPhotos.filter((e) => e !== photo)
    )

    const index = selectedPhoto.findIndex((e) => e === photo)

    setSelectedPhotoForSend((currentFiles) =>
      currentFiles.filter((_, i) => i !== index)
    )
  }

  const handleSaveExpenses = () => {
    const dataForSend = {
      id: expenses?.id,
      type: typeExpenses,
      payment_type: paymentTypeExpenses,
      date: dateExpenses,
      images: selectedPhotoForSend,
      quantity: quantityExpenses,
      amount: amountExpenses,
      currency: currencyExpenses,
      description: descriptionExpenses,
    }

    dispatch(fetchAddExpense(dataForSend)).then((res) => {
      //@ts-ignore
      if (!res.error) {
        setIsVisibleEditExpenses(false)
        setSelectedPhoto([])
        setSelectedPhotoForSend([])
      }
    })
  }

  const handleCloseEditExpenses = () => {
    setIsVisibleEditExpenses(false)
    setAmountExpenses(expenses?.amount)
    setCurrencyExpenses(expenses?.currency)
    setDateExpenses(expenses?.date)
    setDescriptionExpenses(expenses?.description)
    setPaymentTypeExpenses(expenses.payment_type)
    setQuantityExpenses(expenses?.quantity)
    setTypeExpenses(expenses.type)

    setSelectedPhoto([])
    setSelectedPhotoForSend([])
  }

  useLayoutEffect(() => {
    if (textareaRef.current) {
      //@ts-ignore
      textareaRef.current.style.height = 'inherit'
      //@ts-ignore
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
    }
  }, [descriptionExpenses])
  return (
    <>
      {!isVisibleEditExpenses ? (
        <div
          className={classNames(
            styles.bxShadowSmall,
            styles.bgGray,
            styles.border10,
            styles.small2,
            styles.docItem,
            'pl-2 pr-2 mb-3'
          )}
        >
          <div className={classNames(styles.row, 'row')}>
            <div
              className={classNames(
                'col-5 col-sm-3 pr-2 pt-1 pb-1',
                styles.docDate,
                styles.small3
              )}
            >
              {expenses?.date}
            </div>
            <div className="col-4 col-sm-2 pr-2 pt-1 pb-1">
              <b>{expenses?.type === 'fuel' ? 'Топливо' : 'Иное'}</b>
            </div>
            <div
              className={classNames(
                styles.nowrap,
                styles.small3,
                'col-3 col-sm-1 pr-2 pt-1 pb-1'
              )}
              style={{ textAlign: 'right' }}
            >
              {expenses?.quantity} {expenses?.type === 'fuel' ? 'л' : ''}
            </div>
            <div className="col col-sm-2 pt-1 pb-1">
              <b className={classNames(styles.nowrap, styles.colorRed)}>
                {expenses?.amount} {expenses?.currency}
              </b>
              &nbsp;&nbsp;{' '}
              <div className={classNames(styles.small3, styles.colorLightBlue)}>
                {typePayment[expenses?.payment_type]}
              </div>
            </div>
            <div
              className={classNames(styles.nowrap, 'col pt-1 pb-1')}
              style={{ textAlign: 'right' }}
            >
              <PhotoProvider className={styles.photoProvider}>
                {expenses?.images?.map((photo: any, index: number) => {
                  return (
                    //@ts-ignore
                    <PhotoView src={photo?.image?.lg}>
                      {index === 0 ? (
                        <a
                          data-lightbox="note_01"
                          className={classNames(
                            styles.btn,
                            styles.btnSmall2,
                            styles.btnBlueOutline,
                            styles.picBtn,
                            'mr-1'
                          )}
                        >
                          <i
                            className={classNames(
                              styles.icon,
                              styles.icon15,
                              styles.iconPicture
                            )}
                          />
                          {expenses.images?.length}
                        </a>
                      ) : (
                        <img style={{ display: 'none' }} />
                      )}
                    </PhotoView>
                  )
                })}
              </PhotoProvider>
              <EditButton onClick={() => setIsVisibleEditExpenses(true)} />

              <DeleteButton onClick={() => handleDeleteExpenses(expenses)} />
            </div>
            {expenses?.description && (
              <div
                className={classNames(
                  styles.small3,
                  styles.colorGray,
                  'col-12 p-1'
                )}
              >
                {expenses?.description}
              </div>
            )}
          </div>
          {/* <div>
      <a
        href="img/temp/photo-02.jpg"
        data-lightbox="note_money_1"
      ></a>
      <a
        href="img/temp/photo-03.jpg"
        data-lightbox="note_money_1"
      ></a>
    </div> */}
        </div>
      ) : (
        <div
          className={classNames(
            styles.bxShadowSmall,
            styles.bgGray,
            styles.small2,
            styles.border10,
            'pl-3 pr-3 mb-3'
          )}
        >
          <div className={classNames('row', styles.rowNote)}>
            <div className="col-7 col-sm-4 col-md-4 col-lg-4 col-xl-4  pl-1 pr-1 mb-2">
              <div
                className={styles.reportDate}
                id="report_date_input_wrapper2"
              >
                <DateTimePicker
                  id="money_date_input_wrapper"
                  selectedDate={dateExpenses}
                  setSelectedDate={setDateExpenses}
                  placeholder="Дата и время"
                />
              </div>
            </div>
            <div className="col-5 col-sm-3 col-md-3 col-lg-3 col-xl-3 pl-1 pr-1 mb-2">
              <select
                value={typeExpenses}
                onChange={(e) => setTypeExpenses(e.target.value)}
              >
                <option value="fuel">Топливо</option>
                <option value="other">Иное</option>
              </select>
            </div>

            <div className="col-7 col-sm-2 col-md-2 col-lg-2 col-xl-2 pl-1 pr-1 mb-2">
              <input
                value={quantityExpenses}
                onChange={(e) =>
                  //@ts-ignore
                  setQuantityExpenses(e.target.value)
                }
                required
                type="text"
                placeholder="Кол-во"
              />
            </div>

            <div className="col-5 col-sm-3 col-md-3 col-lg-3 col-xl-3 pl-1 pr-1 mb-2">
              <select
                value={paymentTypeExpenses}
                onChange={(e) =>
                  //@ts-ignore

                  setPaymentTypeExpenses(e.target.value)
                }
              >
                <option value="cash">Нал</option>
                <option value="cash_check">Нал (чек)</option>
                <option value="card">Карта</option>
                <option value="card_check">Карта (чек)</option>
                <option value="fuel_card">Топл. карта</option>
              </select>
            </div>

            <div className="col-7 col-sm-4 col-md-4 col-lg-4 col-xl-4 pl-1 pr-1 mb-2">
              <input
                value={amountExpenses}
                //@ts-ignore

                onChange={(e) => setAmountExpenses(e.target.value)}
                required
                type="text"
                placeholder="Сумма"
              />

              <div
                className={classNames(
                  styles.small3,
                  styles.radioTabs,
                  'd-flex flex-wrap justify-content-start'
                )}
                style={{ textAlign: 'left' }}
              >
                <div className="mt-1">
                  <input
                    name={`currency-${expenses.id}`}
                    id={`${expenses.id}BYN`}
                    type="radio"
                    value="BYN"
                    checked={currencyExpenses === 'BYN'}
                    onChange={handleCurrencyChange}
                  />
                  <label
                    className={styles.labelCurrency}
                    htmlFor={`${expenses.id}BYN`}
                  >
                    BYN
                  </label>
                </div>
                <div className="mt-1">
                  <input
                    name={`currency-${expenses.id}`}
                    id={`${expenses.id}EUR`}
                    type="radio"
                    value="EUR"
                    checked={currencyExpenses === 'EUR'}
                    onChange={handleCurrencyChange}
                  />
                  <label
                    className={styles.labelCurrency}
                    htmlFor={`${expenses.id}EUR`}
                  >
                    EUR
                  </label>
                </div>
                <div className="mt-1">
                  <input
                    name={`currency-${expenses.id}`}
                    id={`${expenses.id}RUB`}
                    type="radio"
                    value="RUB"
                    checked={currencyExpenses === 'RUB'}
                    onChange={handleCurrencyChange}
                  />
                  <label
                    className={styles.labelCurrency}
                    htmlFor={`${expenses.id}RUB`}
                  >
                    RUB
                  </label>
                </div>
              </div>
            </div>

            <div className="col-5 col-sm-3 col-md-3 col-lg-3 col-xl-3 pl-1 pr-1 mb-2">
              <div
                className={classNames(
                  styles.btn,
                  styles.btnBlueOutline,
                  styles.btnSmall2,
                  styles.btnWide,
                  styles.loadGallery
                )}
              >
                <b>+ Фото</b>
                <input
                  multiple={true}
                  onChange={(e) => handlePhotoChange(e)}
                  type="file"
                  name="report_gallery"
                  id="report_gallery"
                  accept={IMAGE_TYPE}
                />{' '}
              </div>
            </div>
            {!!selectedPhoto.length && (
              <div
                className={classNames(
                  'col-12 mt-3 mb-3',
                  styles.photoContainer
                )}
              >
                <PhotoProvider className={styles.photoProvider}>
                  {selectedPhoto?.map((photo) => (
                    <PhotoView src={photo}>
                      <div className={styles.photoView}>
                        <img src={photo} alt="" />
                        <span
                          onClick={(e) => {
                            e.stopPropagation()
                            handleDeletePhoto(photo)
                          }}
                          className={styles.del}
                        >
                          ×
                        </span>
                      </div>
                    </PhotoView>
                  ))}
                </PhotoProvider>
              </div>
            )}
            <div className="col-12 col-sm-12 col-md-12 mt-2 pl-1 pr-1">
              <small className={classNames(styles.small2, 'd-block')}>
                Пояснение:
              </small>
            </div>

            <div className="col-12 pl-1 pr-1">
              <textarea
                className={styles.noteText}
                ref={textareaRef}
                id="controlled-textarea"
                value={descriptionExpenses}
                onChange={(e) => setDescriptionExpenses(e.target.value)}
              />
            </div>

            <div className="col-8 pl-1 pr-1 mt-1">
              <div
                className={classNames(
                  styles.btn,
                  styles.btnSmall2,
                  styles.btnBlue
                )}
                onClick={handleSaveExpenses}
              >
                <i
                  className={classNames(styles.icon, styles.iconCheckWhite)}
                ></i>{' '}
                Сохранить
              </div>
            </div>

            <div
              className="col-4 pl-1 pr-1 mt-1"
              style={{ textAlign: 'right' }}
            >
              <div
                onClick={() => handleCloseEditExpenses()}
                className={classNames(
                  styles.btn,
                  styles.btnSmall2,
                  styles.btnBlueOutline
                )}
              >
                Отмена
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

function ReportModal() {
  const { isAdmin, isDriver, isOwner } = getRole()
  const dispatch = useDispatch<AppDispatch>()

  const { currentReport, currentReportId } = useSelector(reportsSelector)
  const [selectedPhoto, setSelectedPhoto] = useState<string[]>([])
  const [selectedPhotoForSend, setSelectedPhotoForSend] = useState<File[]>([])

  const [isVisibleExpenses, setIsVisibleExpenses] = useState(false)
  useClearBodyNoScrollClass()

  const { cars } = useSelector(carsSelector)
  // ДАННЫЕ
  const [user, setUser] = useState(currentReport?.user.id || '')
  const [fuelBefore, setFuelBefore] = useState(currentReport?.fuel_before || '')
  const [fuelAfter, setFuelAfter] = useState(currentReport?.fuel_after || '')
  const [mileageAfter, setMileageAfter] = useState(
    currentReport?.mileage_after || ''
  )
  const [mileageBefore, setMileageBefore] = useState(
    currentReport?.mileage_before || ''
  )
  const [departure, setDeparture] = useState(currentReport?.departure || '')
  const [crossingBorderBack, setCrossingBorderBack] = useState(
    currentReport?.crossing_border_back || ''
  )
  const [crossingBorderThere, setCrossingBorderThere] = useState(
    currentReport?.crossing_border_there || ''
  )
  const [reimbursementDate, setReimbursementDate] = useState(
    currentReport?.reimbursement_date || ''
  )
  const [trailer, setTrailer] = useState(currentReport?.trailer?.id || '')
  const [car, setCar] = useState(currentReport?.car.id || '')
  const [status, setStatus] = useState(currentReport?.status)
  const [avgCons, setAvgCons] = useState(currentReport?.avg_cons || '')
  const textareaRef = useRef(null)

  const [amountExpenses, setAmountExpenses] = useState('')
  const [currencyExpenses, setCurrencyExpenses] = useState('BYN')
  const [dateExpenses, setDateExpenses] = useState('')
  const [descriptionExpenses, setDescriptionExpenses] = useState('')
  const [paymentTypeExpenses, setPaymentTypeExpenses] = useState('cash')
  const [quantityExpenses, setQuantityExpenses] = useState('')
  const [typeExpenses, setTypeExpenses] = useState('fuel')

  const [issuedByn, setIssuedByn] = useState(currentReport?.issued_byn || '')
  const [issuedBynBalance, setIssuedBynBalance] = useState(
    currentReport?.issued_byn_balance || ''
  )
  const [issuedEur, setIssuedEur] = useState(currentReport?.issued_eur || '')
  const [issuedEurBalance, setIssuedEurBalance] = useState(
    currentReport?.issued_eur_balance || ''
  )
  const [issuedRub, setIssuedRub] = useState(currentReport?.issued_rub || '')
  const [issuedRubBalance, setIssuedRubBalance] = useState(
    currentReport?.issued_rub_balance || ''
  )

  useEffect(() => {
    if (currentReport) {
      setUser(currentReport.user.id || '')
      setFuelBefore(currentReport.fuel_before || '')
      setFuelAfter(currentReport.fuel_after || '')
      setMileageAfter(currentReport.mileage_after || '')
      setMileageBefore(currentReport.mileage_before || '')
      setDeparture(currentReport.departure || '')
      setCrossingBorderBack(currentReport.crossing_border_back || '')
      setCrossingBorderThere(currentReport.crossing_border_there || '')
      setReimbursementDate(currentReport.reimbursement_date || '')
      setTrailer(currentReport.trailer?.id || '')
      setCar(currentReport.car.id || '')
      setStatus(currentReport.status)
      setAvgCons(currentReport.avg_cons || '')
      setIssuedByn(currentReport.issued_byn || '')
      setIssuedBynBalance(currentReport.issued_byn_balance || '')
      setIssuedEur(currentReport.issued_eur || '')
      setIssuedEurBalance(currentReport.issued_eur_balance || '')
      setIssuedRub(currentReport.issued_rub || '')
      setIssuedRubBalance(currentReport.issued_rub_balance || '')
    }
  }, [currentReport])

  const isNewReport = !!currentReport
  const isEditMyReport = window.location.pathname === PATH.MY_REPORTS

  // my_report && not new report && status draft
  const isEditDraftReport =
    isEditMyReport && isNewReport && currentReport?.status === 'draft'

  const [errors, setErrors] = useState<{
    departure: boolean
    mileageBefore: boolean
    car: boolean
    // trailer: boolean
    fuelBefore: boolean

    mileageAfter?: boolean
    fuelAfter?: boolean
  }>({
    departure: false,
    mileageBefore: false,
    car: false,
    // trailer: false,
    fuelBefore: false,

    mileageAfter: false,
    fuelAfter: false,
  })

  const checkValidation = () => {
    let newErrors = {
      departure: !departure,
      mileageBefore: !mileageBefore,
      car: !car,
      fuelBefore: !fuelBefore,
      // trailer: !trailer,

      mileageAfter: false,
      fuelAfter: false,
    }

    if (!!currentReport?.id) {
      newErrors = {
        ...newErrors,
        mileageAfter: !mileageAfter,
        fuelAfter: !fuelAfter,
      }
    }

    setErrors(newErrors)
  }
  const handleSaveReport = (e: any, statusReport?: string) => {
    e.preventDefault()

    const commonParams = {
      departure,
      mileageBefore,
      car,
      fuelBefore,
      fuelAfter,
      mileageAfter,
      trailer,
      crossingBorderThere,
      crossingBorderBack,
      avgCons,
      reimbursementDate,
      status: statusReport || status,
      issuedByn,
      issuedEur,
      issuedRub,
      user,
    }

    const newErrors = {
      departure: !departure,
      mileageBefore: !mileageBefore,
      car: !car,
      // trailer: !trailer,
      fuelBefore: !fuelBefore,
    }

    setErrors(newErrors)

    const hasErrors = Object.values(newErrors).some((error) => error)

    if (!hasErrors) {
      if (currentReportId) {
        dispatch(fetchAddReports({ ...commonParams, id: currentReportId }))
      } else {
        dispatch(fetchAddReports(commonParams))
      }
    }
  }

  const handleSentReport = (e: any) => {
    e.preventDefault()

    const commonParams = {
      departure,
      mileageBefore,
      car,
      fuelBefore,
      fuelAfter,
      mileageAfter,
      trailer,
      crossingBorderThere,
      crossingBorderBack,
      avgCons,
      reimbursementDate,
      status: 'sent',
    }

    const newErrors = {
      departure: !departure,
      mileageBefore: !mileageBefore,
      car: !car,
      fuelBefore: !fuelBefore,

      mileageAfter: !mileageAfter,
      fuelAfter: !fuelAfter,
      // crossingBorderThere: !crossingBorderThere,
      // crossingBorderBack: !crossingBorderBack,
      // reimbursementDate: !reimbursementDate,
    }

    setErrors(newErrors)

    const hasErrors = Object.values(newErrors).some((error) => error)
    if (!hasErrors) {
      if (currentReportId) {
        dispatch(fetchAddReports({ ...commonParams, id: currentReportId }))
      } else {
        dispatch(fetchAddReports(commonParams))
      }
    }
  }

  useLayoutEffect(() => {
    if (textareaRef.current) {
      //@ts-ignore
      textareaRef.current.style.height = 'inherit'
      //@ts-ignore
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
    }
  }, [descriptionExpenses])

  const handlePhotoChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    // Проверка на null перед использованием Array.from
    if (event.target.files) {
      const files = event.target.files
      const maxSize = 50 * 1024 * 1024 // 50MB в байтах

      for (let i = 0; i < files.length; i++) {
        if (files[i].size > maxSize) {
          toast.error(
            `Файл ${files[i].name} превышает максимальный размер 50MB.`
          )
          return
        }
      }
      const filesArray: FileArray = Array.from(event.target.files)

      setSelectedPhotoForSend((prevPhotos: FileArray) => [
        ...prevPhotos,
        ...filesArray,
      ])

      const photosUrls: string[] = filesArray.map((file) =>
        URL.createObjectURL(file)
      )

      setSelectedPhoto((prevUrls: string[]) => [...prevUrls, ...photosUrls])
    }
  }

  useEffect(() => {
    dispatch(fetchUsers())
    dispatch(fetchCars())
  }, [])

  const driversAndAssistants = useSelector(driversAndAssistantsSelector)

  //@ts-ignore
  useSelector(() => {}, [selectedPhoto])

  const handleDeletePhoto = (photo: string) => {
    setSelectedPhoto((currentPhotos) =>
      currentPhotos.filter((e) => e !== photo)
    )

    const index = selectedPhoto.findIndex((e) => e === photo)

    setSelectedPhotoForSend((currentFiles) =>
      currentFiles.filter((_, i) => i !== index)
    )
  }

  const handleCurrencyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setCurrencyExpenses(value)
  }

  const handleSaveExpenses = () => {
    const dataForSend = {
      type: typeExpenses,
      payment_type: paymentTypeExpenses,
      date: dateExpenses,
      images: selectedPhotoForSend,
      quantity: quantityExpenses,
      amount: amountExpenses,
      currency: currencyExpenses,
      description: descriptionExpenses,
    }

    dispatch(fetchAddExpense(dataForSend)).then((res) => {
      //@ts-ignore
      if (!res.error) {
        setAmountExpenses('')
        setCurrencyExpenses('BYN')
        setDateExpenses('')
        setDescriptionExpenses('')
        setPaymentTypeExpenses('cash')
        setQuantityExpenses('')
        setTypeExpenses('fuel')
        setSelectedPhoto([])
        setIsVisibleExpenses(false)
      }
    })
  }

  useEffect(() => {
    if (currentReportId) {
      dispatch(fetchCurrentReport(currentReportId))
    }
  }, [])

  return (
    <div style={{ display: 'block' }} className={styles.win}>
      <div className={styles.winContainer}>
        <div className={styles.winContent}>
          <div
            onClick={() => dispatch(setIsVisibleModalReport(false))}
            className={styles.close}
          >
            ×
          </div>
          <div className={styles.winContentFulltext}>
            <h3
              style={{ textAlign: 'center' }}
              className={classNames(styles.h3)}
            >
              {currentReport ? 'Редактирование отчёта' : 'Новый отчёт'}
            </h3>
            {!isEditMyReport && (
              <div className="mb-3" style={{ textAlign: 'center' }}>
                <button
                  onClick={() => dispatch(fetchReportFile())}
                  className={classNames(
                    styles.btn,
                    styles.btnSmall2,
                    styles.btnBlueOutline
                  )}
                >
                  <i
                    className={classNames(styles.icon, styles.iconDownload)}
                  ></i>
                  Скачать отчёт PDF
                </button>
              </div>
            )}
            <form onSubmit={handleSaveReport}>
              {/* <!-- ТОЛЬКО ДЛЯ АДМИНА ИЛИ ВЛАДЕЛЬЦА --> */}
              {isNewReport && !isEditMyReport && (
                <div
                  className={classNames(
                    styles.bxShadowSmall,
                    styles.bgGray,
                    styles.border10,
                    styles.small2,
                    styles.docItem,
                    'pt-2 pb-2 pl-3 pr-3 mb-3 '
                  )}
                >
                  <div
                    className={classNames(
                      styles.row,
                      'row',
                      'align-items-start'
                    )}
                  >
                    <div className="col-12 col-sm-6 mt-1 mb-1">
                      <small
                        className={classNames(styles.small2, 'd-block mb-1')}
                      >
                        Водитель
                      </small>
                      <select
                        value={user}
                        onChange={(e) => {
                          setUser(e.target.value)
                        }}
                      >
                        {driversAndAssistants.map((user) => {
                          return (
                            <option key={user.id} value={user.id}>
                              {user.name}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                    <div className="col-6 col-sm-3 mt-1 mb-1">
                      <small
                        className={classNames(styles.small2, 'd-block mb-1')}
                      >
                        Ср. расход, л/100
                      </small>
                      <input
                        value={avgCons}
                        onChange={(e) => setAvgCons(e.target.value)}
                        type="text"
                      />
                    </div>
                    <div className="col-6 col-sm-3 mt-1 mb-1">
                      <small
                        className={classNames(styles.small2, 'd-block mb-1')}
                      >
                        Статус
                      </small>
                      <select
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                      >
                        <option key="draft" value="draft" selected>
                          Черновик
                        </option>
                        <option key="sent" value="sent">
                          Отправлен
                        </option>
                        <option key="closed" value="closed">
                          Закрыт
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              )}
              {/* <!-- / ТОЛЬКО ДЛЯ АДМИНА ИЛИ ВЛАДЕЛЬЦА --> */}

              <div className="row">
                <div className="col-12 col-sm-6 col-md-6 col-lg-6 mb-3">
                  <label
                    htmlFor="report_date_input_departure"
                    className={classNames(
                      styles.small2,
                      styles.label,
                      'd-block mb-1'
                    )}
                  >
                    Дата и время выезда*
                  </label>
                  <div
                    className={classNames(
                      styles.reportDate,
                      styles.border5,
                      errors.departure ? styles.isError : ''
                    )}
                    onClick={() => {
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        departure: false,
                      }))
                    }}
                    id="report_date_input_wrapper1"
                  >
                    <DateTimePicker
                      id="report_date_input_departure"
                      selectedDate={departure}
                      setSelectedDate={setDeparture}
                    />
                  </div>
                </div>

                <div className="col-12 col-sm-6 col-md-6 col-lg-6 mb-3">
                  <div className="row">
                    <div className="col-6 mb-3 pr-1">
                      <small
                        className={classNames(styles.small2, 'd-block mb-1')}
                      >
                        Пробег До*, км
                      </small>
                      <input
                        className={errors.mileageBefore ? styles.isError : ''}
                        value={mileageBefore}
                        onChange={(e) => {
                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            mileageBefore: false,
                          }))

                          setMileageBefore(e.target.value)
                        }}
                        required
                        type="text"
                      />
                    </div>
                    <div className="col-6 mb-3 pl-1">
                      <small
                        className={classNames(styles.small2, 'd-block mb-1')}
                      >
                        Пробег После, км
                      </small>
                      <input
                        className={errors.mileageAfter ? styles.isError : ''}
                        value={mileageAfter}
                        onChange={(e) => {
                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            mileageAfter: false,
                          }))
                          setMileageAfter(e.target.value)
                        }}
                        type="text"
                      />
                    </div>

                    <div className="col-6 mb-3 pr-1">
                      <small
                        className={classNames(styles.small2, 'd-block mb-1')}
                      >
                        Топливо До*, л
                      </small>
                      <input
                        className={errors.fuelBefore ? styles.isError : ''}
                        value={fuelBefore}
                        onChange={(e) => {
                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            fuelBefore: false,
                          }))
                          setFuelBefore(e.target.value)
                        }}
                        type="text"
                      />
                    </div>
                    <div className="col-6 mb-3 pl-1">
                      <small
                        className={classNames(styles.small2, 'd-block mb-1')}
                      >
                        Топливо После, л
                      </small>
                      <input
                        className={errors.fuelAfter ? styles.isError : ''}
                        value={fuelAfter}
                        onChange={(e) => {
                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            fuelAfter: false,
                          }))
                          setFuelAfter(e.target.value)
                        }}
                        type="text"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-6 col-sm-6 mb-3">
                  <small className={classNames(styles.small2, 'd-block mb-1')}>
                    Грузовик*
                  </small>
                  <select
                    className={errors.car ? styles.isError : ''}
                    value={car}
                    onChange={(e) => {
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        car: false,
                      }))

                      setCar(e.target.value)
                    }}
                  >
                    <option value="" disabled hidden />
                    {cars.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-6 col-sm-6 mb-3">
                  <small className={classNames(styles.small2, 'd-block mb-1')}>
                    Прицеп
                  </small>
                  <select
                    // className={errors.trailer ? styles.isError : ''}
                    value={trailer}
                    onChange={(e) => {
                      // setErrors((prevErrors) => ({
                      //   ...prevErrors,
                      //   trailer: false,
                      // }))
                      setTrailer(e.target.value)
                    }}
                  >
                    <option value="" disabled hidden />
                    {cars.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-12 col-sm-6 col-md-6 col-lg-6 mb-3">
                  <label
                    htmlFor="report_date_input_tuda"
                    className={classNames(
                      styles.small2,
                      styles.label,
                      'd-block mb-1'
                    )}
                  >
                    Пересечение границы туда
                  </label>
                  <DateTimePicker
                    id="report_date_input_tuda"
                    selectedDate={crossingBorderThere}
                    setSelectedDate={setCrossingBorderThere}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-6 mb-3">
                  <label
                    htmlFor="report_date_input_obratno"
                    className={classNames(
                      styles.small2,
                      styles.label,
                      'd-block mb-1'
                    )}
                  >
                    Пересечение границы обратно
                  </label>
                  <DateTimePicker
                    id="report_date_input_obratno"
                    selectedDate={crossingBorderBack}
                    setSelectedDate={setCrossingBorderBack}
                  />
                </div>

                {currentReportId && (
                  <>
                    {isEditMyReport ? (
                      (issuedByn ||
                        issuedRub ||
                        issuedEur ||
                        issuedBynBalance ||
                        issuedRubBalance ||
                        issuedEurBalance) && (
                        <div className="col-12 mt-3 mb-2">
                          <div className="mb-2">
                            <b>Выдано в рейс</b>
                          </div>
                          <div className="row">
                            {(issuedByn || issuedBynBalance) && (
                              <div
                                className={classNames(
                                  styles.small,
                                  'col-4 pr-0 small'
                                )}
                              >
                                <div className="mb-1">
                                  <b>{issuedByn || 0}</b>{' '}
                                  <span
                                    className={classNames(
                                      styles.small3,
                                      styles.colorGray
                                    )}
                                  >
                                    BYN
                                  </span>
                                </div>
                                <div className={classNames(styles.small3)}>
                                  Ост.{' '}
                                  <b className={styles.colorRed}>
                                    {issuedBynBalance || 0}
                                  </b>{' '}
                                  <span
                                    className={classNames(
                                      styles.small3,
                                      styles.colorRed
                                    )}
                                  >
                                    BYN
                                  </span>
                                </div>
                              </div>
                            )}

                            {(issuedRub || issuedRubBalance) && (
                              <div
                                className={classNames(
                                  styles.small,
                                  'col-4 pr-0 small'
                                )}
                              >
                                <div className="mb-1">
                                  <b>{issuedRub || 0}</b>{' '}
                                  <span
                                    className={classNames(
                                      styles.small3,
                                      styles.colorGray
                                    )}
                                  >
                                    RUB
                                  </span>
                                </div>
                                <div className={classNames(styles.small3)}>
                                  Ост.{' '}
                                  <b className={styles.colorRed}>
                                    {issuedRubBalance || 0}
                                  </b>{' '}
                                  <span
                                    className={classNames(
                                      styles.small3,
                                      styles.colorRed
                                    )}
                                  >
                                    RUB
                                  </span>
                                </div>
                              </div>
                            )}

                            {(issuedEur || issuedEurBalance )&& (
                              <div
                                className={classNames(
                                  styles.small,
                                  'col-4 pr-0 small'
                                )}
                              >
                                <div className="mb-1">
                                  <b>{issuedEur || 0}</b>{' '}
                                  <span
                                    className={classNames(
                                      styles.small3,
                                      styles.colorGray
                                    )}
                                  >
                                    EUR
                                  </span>
                                </div>
                                <div className={classNames(styles.small3)}>
                                  Ост.{' '}
                                  <b className={styles.colorRed}>
                                    {issuedEurBalance || 0}
                                  </b>{' '}
                                  <span
                                    className={classNames(
                                      styles.small3,
                                      styles.colorRed
                                    )}
                                  >
                                    EUR
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )
                    ) : (
                      <div className="col-12 mt-3 mb-2">
                        <div className="">
                          <b>Деньги</b>
                        </div>
                        <div
                          className={classNames(
                            styles.small2,
                            'row align-items-start'
                          )}
                        >
                          <div
                            className={classNames(
                              styles.small2,
                              'col-12 col-sm-8 mt-1 mb-1'
                            )}
                          >
                            <small
                              className={classNames(
                                styles.small2,
                                'd-block mb-1'
                              )}
                            >
                              Выдано в рейс
                            </small>
                            <div className="row">
                              <div
                                className={classNames(
                                  styles.small2,
                                  'col-4 pr-1'
                                )}
                              >
                                <div
                                  className={classNames(
                                    styles.inputCurrency,
                                    styles.inputByn,
                                    'mb-1'
                                  )}
                                >
                                  <input
                                    value={issuedByn}
                                    onChange={(e) =>
                                      setIssuedByn(e.target.value)
                                    }
                                    type="text"
                                    placeholder="Сумма"
                                  />
                                </div>
                                <div className={classNames(styles.small3)}>
                                  Ост.{' '}
                                  <b className={classNames(styles.colorRed)}>
                                    {issuedBynBalance || 0}
                                  </b>{' '}
                                  <span className={classNames(styles.colorRed)}>
                                    BYN
                                  </span>
                                </div>
                              </div>

                              <div
                                className={classNames(
                                  styles.small2,
                                  'col-4 pl-1 pr-1'
                                )}
                              >
                                <div
                                  className={classNames(
                                    styles.inputCurrency,
                                    styles.inputRub,
                                    'mb-1'
                                  )}
                                >
                                  <input
                                    value={issuedRub}
                                    onChange={(e) =>
                                      setIssuedRub(e.target.value)
                                    }
                                    type="text"
                                    placeholder="Сумма"
                                  />
                                </div>
                                <div className={classNames(styles.small3)}>
                                  Ост.{' '}
                                  <b className={classNames(styles.colorRed)}>
                                    {issuedRubBalance || 0}
                                  </b>{' '}
                                  <span className={classNames(styles.colorRed)}>
                                    RUB
                                  </span>
                                </div>
                              </div>

                              <div
                                className={classNames(
                                  styles.small2,
                                  'col-4 pl-1'
                                )}
                              >
                                <div
                                  className={classNames(
                                    styles.inputCurrency,
                                    styles.inputEur,
                                    'mb-1'
                                  )}
                                >
                                  <input
                                    value={issuedEur}
                                    onChange={(e) =>
                                      setIssuedEur(e.target.value)
                                    }
                                    type="text"
                                    placeholder="Сумма"
                                  />
                                </div>
                                <div className={classNames(styles.small3)}>
                                  Ост.{' '}
                                  <b className={classNames(styles.colorRed)}>
                                    {issuedEurBalance || 0}
                                  </b>{' '}
                                  <span className={classNames(styles.colorRed)}>
                                    {' '}
                                    EUR
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className={classNames(
                              styles.small2,
                              'col-12 col-sm-4 mt-1 mb-1'
                            )}
                          >
                            <small
                              className={classNames(
                                styles.small2,
                                'd-block mb-1'
                              )}
                            >
                              Возмещ. расходов
                            </small>
                            <div
                              className="report-date"
                              id="money_date_input_wrapper"
                            >
                              <DateTimePicker
                                id="money_date_input_wrapper"
                                selectedDate={reimbursementDate}
                                setSelectedDate={setReimbursementDate}
                                addPosition={true}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}

                {isNewReport && (
                  <div className="col-12 col-sm-12 mt-3 mb-2">
                    <b className="mr-2">Расходы</b>{' '}
                    <a
                      onClick={() => setIsVisibleExpenses(!isVisibleExpenses)}
                      className={classNames(
                        styles.btn,
                        styles.btnSmall2,
                        styles.btnBlueOutline,
                        styles.btnMarginMobile
                      )}
                    >
                      <b>+ Добавить</b>
                    </a>
                  </div>
                )}
                <div className="col-12">
                  {isVisibleExpenses && (
                    <div
                      className={classNames(
                        styles.bxShadowSmall,
                        styles.bgGray,
                        styles.small2,
                        styles.border10,
                        'pl-3 pr-3 mb-3'
                      )}
                    >
                      <div className={classNames('row', styles.rowNote)}>
                        <div className="col-7 col-sm-4 col-md-4 col-lg-4 col-xl-4  pl-1 pr-1 mb-2">
                          <div
                            className={styles.reportDate}
                            id="report_date_input_wrapper2"
                          >
                            <DateTimePicker
                              id="money_date_input_wrapper"
                              selectedDate={dateExpenses}
                              setSelectedDate={setDateExpenses}
                              placeholder="Дата и время"
                            />
                          </div>
                        </div>
                        <div className="col-5 col-sm-3 col-md-3 col-lg-3 col-xl-3 pl-1 pr-1 mb-2">
                          <select
                            value={typeExpenses}
                            onChange={(e) => setTypeExpenses(e.target.value)}
                          >
                            <option value="fuel">Топливо</option>
                            <option value="other">Иное</option>
                          </select>
                        </div>

                        <div className="col-7 col-sm-2 col-md-2 col-lg-2 col-xl-2 pl-1 pr-1 mb-2">
                          <input
                            value={quantityExpenses}
                            onChange={(e) =>
                              setQuantityExpenses(e.target.value)
                            }
                            required
                            type="text"
                            placeholder="Кол-во"
                          />
                        </div>

                        <div className="col-5 col-sm-3 col-md-3 col-lg-3 col-xl-3 pl-1 pr-1 mb-2">
                          <select
                            value={paymentTypeExpenses}
                            onChange={(e) =>
                              setPaymentTypeExpenses(e.target.value)
                            }
                          >
                            <option value="cash">Нал</option>
                            <option value="cash_check">Нал (чек)</option>
                            <option value="card">Карта</option>
                            <option value="card_check">Карта (чек)</option>
                            <option value="fuel_card">Топл. карта</option>
                          </select>
                        </div>

                        <div className="col-7 col-sm-4 col-md-4 col-lg-4 col-xl-4 pl-1 pr-1 mb-2">
                          <input
                            value={amountExpenses}
                            onChange={(e) => setAmountExpenses(e.target.value)}
                            required
                            type="text"
                            placeholder="Сумма"
                          />

                          <div
                            className={classNames(
                              styles.small3,
                              styles.radioTabs,
                              'd-flex flex-wrap justify-content-start'
                            )}
                            style={{ textAlign: 'left' }}
                          >
                            <div className="mt-1">
                              <input
                                name="currency"
                                id="currency_radio6"
                                type="radio"
                                value="BYN"
                                checked={currencyExpenses === 'BYN'}
                                onChange={handleCurrencyChange}
                              />
                              <label
                                className={styles.labelCurrency}
                                htmlFor="currency_radio6"
                              >
                                BYN
                              </label>
                            </div>
                            <div className="mt-1">
                              <input
                                name="currency"
                                id="currency_radio4"
                                type="radio"
                                value="EUR"
                                checked={currencyExpenses === 'EUR'}
                                onChange={handleCurrencyChange}
                              />
                              <label
                                className={styles.labelCurrency}
                                htmlFor="currency_radio4"
                              >
                                EUR
                              </label>
                            </div>
                            <div className="mt-1">
                              <input
                                name="currency"
                                id="currency_radio5"
                                type="radio"
                                value="RUB"
                                checked={currencyExpenses === 'RUB'}
                                onChange={handleCurrencyChange}
                              />
                              <label
                                className={styles.labelCurrency}
                                htmlFor="currency_radio5"
                              >
                                RUB
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="col-5 col-sm-3 col-md-3 col-lg-3 col-xl-3 pl-1 pr-1 mb-2">
                          <div
                            className={classNames(
                              styles.btn,
                              styles.btnBlueOutline,
                              styles.btnSmall2,
                              styles.btnWide,
                              styles.loadGallery
                            )}
                          >
                            <b>+ Фото</b>
                            <input
                              multiple={true}
                              onChange={(e) => handlePhotoChange(e)}
                              type="file"
                              name="report_gallery"
                              id="report_gallery"
                              accept={IMAGE_TYPE}
                            />{' '}
                          </div>
                        </div>
                        {!!selectedPhoto.length && (
                          <div
                            className={classNames(
                              'col-12 mt-3 mb-3',
                              styles.photoContainer
                            )}
                          >
                            <PhotoProvider className={styles.photoProvider}>
                              {selectedPhoto?.map((photo) => (
                                <PhotoView src={photo}>
                                  <div className={styles.photoView}>
                                    <img src={photo} alt="" />
                                    <span
                                      onClick={(e) => {
                                        e.stopPropagation()
                                        handleDeletePhoto(photo)
                                      }}
                                      className={styles.del}
                                    >
                                      ×
                                    </span>
                                  </div>
                                </PhotoView>
                              ))}
                            </PhotoProvider>
                          </div>
                        )}
                        <div className="col-12 col-sm-12 col-md-12 mt-2 pl-1 pr-1">
                          <small
                            className={classNames(styles.small2, 'd-block')}
                          >
                            Пояснение:
                          </small>
                        </div>

                        <div className="col-12 pl-1 pr-1">
                          <textarea
                            className={styles.noteText}
                            ref={textareaRef}
                            id="controlled-textarea"
                            value={descriptionExpenses}
                            onChange={(e) =>
                              setDescriptionExpenses(e.target.value)
                            }
                          />
                        </div>

                        <div className="col-8 pl-1 pr-1 mt-1">
                          <div
                            className={classNames(
                              styles.btn,
                              styles.btnSmall2,
                              styles.btnBlue
                            )}
                            onClick={handleSaveExpenses}
                          >
                            <i
                              className={classNames(
                                styles.icon,
                                styles.iconCheckWhite
                              )}
                            ></i>{' '}
                            Сохранить
                          </div>
                        </div>

                        <div
                          className="col-4 pl-1 pr-1 mt-1"
                          style={{ textAlign: 'right' }}
                        >
                          <div
                            onClick={() => setIsVisibleExpenses(false)}
                            className={classNames(
                              styles.btn,
                              styles.btnSmall2,
                              styles.btnBlueOutline
                            )}
                          >
                            Отмена
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {currentReport?.expenses &&
                    currentReport.expenses.map((item: any) => (
                      <Expenses expenses={item} />
                    ))}
                </div>
              </div>

              {!isEditDraftReport ? (
                <div className="row mt-2">
                  <div
                    className="col-12 col-sm-3 mt-1 mb-1"
                    style={{ textAlign: 'left' }}
                  ></div>
                  <div
                    className="col-12 col-sm-6 mt-1 mb-1"
                    style={{ textAlign: 'center' }}
                  >
                    <button
                      onClick={checkValidation}
                      className={classNames(
                        styles.btn,
                        'd-block',
                        'd-sm-inline-block'
                      )}
                    >
                      <i
                        className={classNames(
                          styles.icon,
                          styles.iconCheckWhite
                        )}
                      />{' '}
                      Сохранить
                    </button>
                  </div>
                  <div
                    className="col-12 col-sm-3 mt-1 mb-1"
                    style={{ textAlign: 'right' }}
                  >
                    <div
                      className={classNames(
                        styles.btn,
                        styles.btnSmall,
                        styles.btnBlueOutline,
                        'd-block d-sm-inline-block'
                      )}
                      onClick={() => dispatch(setIsVisibleModalReport(false))}
                    >
                      Отмена
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row mt-2">
                  <div
                    className="col-12 col-sm-9 mt-1 mb-1"
                    style={{ textAlign: 'left' }}
                  >
                    <button
                      onClick={(e) => {
                        e.preventDefault()
                        handleSaveReport(e, 'draft')
                      }}
                      style={{ marginRight: '10px' }}
                      className={classNames(
                        styles.btn,
                        styles.btnBlue,
                        'mt-1 d-block d-sm-inline-block'
                      )}
                    >
                      Сохранить
                    </button>
                    <button
                      className={classNames(
                        styles.btn,
                        'd-block mt-1 d-sm-inline-block'
                      )}
                      onClick={(e) => {
                        e.preventDefault()
                        handleSentReport(e)
                      }}
                    >
                      <i
                        className={classNames(
                          styles.icon,
                          styles.iconCheckWhite
                        )}
                      ></i>{' '}
                      Отправить
                    </button>
                  </div>
                  <div
                    className="col-12 col-sm-3 mt-1 mb-1"
                    style={{ textAlign: 'right' }}
                  >
                    <div
                      className={classNames(
                        styles.btn,
                        styles.btnSmall,
                        styles.btnBlueOutline,
                        'd-block d-sm-inline-block'
                      )}
                      onClick={() => dispatch(setIsVisibleModalReport(false))}
                    >
                      Отмена
                    </div>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReportModal
