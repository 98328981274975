import { createSlice } from '@reduxjs/toolkit'
import { Range } from 'react-date-range'
import { DEFINEDS } from '../../constants/date'

export interface InitState {
  calendarRange: Array<Range>
  selectedTrucks: { id: string; name: string }[]
  selectedDrivers: { id: string; name: string }[]
}

const initialState: InitState = {
  calendarRange: [
    {
      startDate: DEFINEDS.startOfMonth,
      endDate: DEFINEDS.endOfMonth,
      key: 'selection',
    },
  ],
  selectedTrucks: [],
  selectedDrivers: [],
}

const updateURL = (filters: InitState) => {
  const query = new URLSearchParams();
  
  if (filters.selectedTrucks.length > 0) {
  query.set('car_id', filters.selectedTrucks.map(truck => truck.id).join(','));
  }
  
  if (filters.selectedDrivers.length > 0) {
  query.set('user_id', filters.selectedDrivers.map(driver => driver.id).join(','));
  }
  
  window.history.pushState({}, '', `?${query.toString()}`);
  };


export const reportFilterSlice = createSlice({
  name: 'reportFilter',
  initialState,
  reducers: {
    setCalendarRange: (state, action) => {
      return { ...state, calendarRange: action.payload }
    },
    setSelectedTrucks: (state, action) => {
      state.selectedTrucks = action.payload;
      updateURL(state);
      },
      setSelectedDrivers: (state, action) => {
        state.selectedDrivers = action.payload;
        updateURL(state);
        },
  },
})

export const { setCalendarRange, setSelectedTrucks, setSelectedDrivers } =
  reportFilterSlice.actions

export default reportFilterSlice.reducer
