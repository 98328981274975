import classNames from 'classnames'
import styles from './ReportsResult.module.css'
import { reportsSelector } from '../../../redux/selectors/reports-selector'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { AppDispatch } from '../../../redux/store'
import {
  setCurrentReport,
  setCurrentReportId,
  setIsVisibleDeleteModalReport,
  setIsVisibleModalReport,
} from '../../../redux/reducers/reports'
import { getRole } from '../../../utils/getRole'
import EditButton from '../../../components/buttons/actions-button/EditButton'
import DeleteButton from '../../../components/buttons/actions-button/DeleteButton'
import { setIsVisibleModalCar } from '../../../redux/reducers/cars'
const BUTTON_INFO = [
  {
    color: 'bgRed',
    title: 'Расходы',
    count: '',
  },
  {
    color: 'bgBlue',
    title: 'Расстояние',
    count: '',
  },
]

function ReportsResult() {
  const dispatch = useDispatch<AppDispatch>()
  const [buttonInfo, setButtonInfo] = useState(BUTTON_INFO)
  const { reports } = useSelector(reportsSelector)

  const [sortField, setSortField] = useState<number | string | null>(null)
  const [sortOrder, setSortOrder] = useState('asc')

  const [sortNewReports, setSortNewReports] = useState<any>([])
  const sortReports = (field: any) => {
    const order = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc'
    setSortField(field)
    setSortOrder(order)

    const sortedReports = [...reports].sort((a, b) => {
      let valueA, valueB

      switch (field) {
        case 'Выезд':
          valueA = new Date(a.departure)
          valueB = new Date(b.departure)
          break
        case 'Машина/Водитель':
          valueA = a.car.name
          valueB = b.car.name
          break
        // case 'Расходы':
        //   valueA = a.distance
        //   valueB = b.distance
        //   break
        case 'Дистанция':
          //@ts-ignore
          valueA = new Date(a.mileage_after) - new Date(a.mileage_before)
          //@ts-ignore

          valueB = new Date(b.mileage_after) - new Date(b.mileage_before)
          break
        // case 'Expenses':
        //   valueA = a.cost
        //   valueB = b.cost
        //   break
        default:
          return 0
      }

      if (valueA < valueB) {
        return order === 'asc' ? -1 : 1
      }
      if (valueA > valueB) {
        return order === 'asc' ? 1 : -1
      }
      return 0
    })

    setSortNewReports(sortedReports)
  }

  useEffect(() => {
    setSortNewReports(reports)
  }, [reports])

  useEffect(() => {
    const sumDistance = reports.reduce((totalMileage, report) => {
      //@ts-ignore
      const mileageBefore = report.mileage_before || 0
      //@ts-ignore

      const mileageAfter = report.mileage_after || 0
      const mileageDifference = mileageAfter - mileageBefore
      return totalMileage + (!!mileageAfter ? mileageDifference : 0)
    }, 0)

    const sumCost = reports?.reduce((acc, report) => {
      return acc + Number(report.all_expenses || 0)
    }, 0)

    setButtonInfo(
      buttonInfo?.map((info) => {
        if (info.title === 'Расходы') {
          return {
            ...info,
            count: `${sumCost?.toLocaleString('en', { minimumFractionDigits: 0, maximumFractionDigits: 0 })} р`,
          }
        } else if (info.title === 'Расстояние') {
          return {
            ...info,
            count: `${sumDistance?.toLocaleString('en', { minimumFractionDigits: 0, maximumFractionDigits: 0 })} km`,
          }
        }
        return info
      })
    )
  }, [reports])

  const handleEditReport = (item: any) => {
    dispatch(setCurrentReportId(item.id))
    dispatch(setIsVisibleModalReport(true))
  }

  const handleDeleteReport = (item: any) => {
    dispatch(setCurrentReportId(item.id))
    dispatch(setIsVisibleDeleteModalReport(true))
  }

  const getStatus = (status: any) => {
    switch (status) {
      case 'draft':
        return 'Черновик'
      case 'sent':
        return 'Отправлен'
      case 'closed':
        return 'Закрыт'
      default:
        return ''
    }
  }

  return (
    <>
      <div className={classNames('row', styles.totalAmountPanel)}>
        {buttonInfo.map(({ color, title, count }) => (
          <div key={title} className="col-6 col-sm-6 col-md-6 col-lg-6">
            <div className={classNames(styles.colCont, styles[color])}>
              <p className={styles.small2}>{title}</p>
              <b className={styles.fontBig}>{count}</b>
            </div>
          </div>
        ))}
      </div>

      {!sortNewReports.length && (
        <div className="mt-3 mb-3" style={{ textAlign: 'center' }}>
          <div
            style={{ textAlign: 'center' }}
            className={classNames(styles.fontBig, styles.colorGray, 'mb-5')}
          >
            За выбранный период отчётов нет
          </div>
        </div>
      )}
      {/* <div
          onClick={() => {
            dispatch(setIsVisibleModalReport(true))
          }}
          className={styles.btn}
        >
          <i className={classNames(styles.icon, styles.iconReportsWhite)}></i>{' '}
          Send daily report
        </div> */}

      {!!sortNewReports.length && (
        <div className={styles.tableBigWrapper}>
          <table
            className={classNames(styles.tableBig, styles.small2)}
            cellPadding="0"
            cellSpacing="0"
          >
            <div
            // className={styles.tableBigWrapper}
            >
              <div
                className={classNames(
                  styles.docItem,
                  styles.small2,
                  'pl-2 pr-2 mt-4 mb-2'
                )}
              >
                <div className={classNames(styles.row, 'row')}>
                  <div
                    onClick={() => sortReports('item')}
                    className="d-none d-xl-block col-auto col-xl-2"
                  >
                    <span
                      className={classNames(
                        styles.colorGray,
                        styles.tableSortCol,
                        styles.sortDown
                      )}
                    >
                      Выезд
                    </span>
                  </div>

                  <div
                    onClick={() => sortReports('item')}
                    className="d-none d-xl-block col-xl-3"
                  >
                    <span className={styles.colorGray}>Машина</span>
                  </div>

                  <div
                    onClick={() => sortReports('item')}
                    className="d-none d-xl-block col-xl-3"
                  >
                    <span className={styles.colorGray}>Граница</span>
                  </div>

                  <div
                    onClick={() => sortReports('item')}
                    className={classNames(
                      styles.docMoney,
                      'col-auto col-xl-auto pr-2'
                    )}
                  >
                    <span
                      className={classNames(
                        styles.colorGray,
                        styles.tableSortCol,
                        'd-none d-xl-block'
                      )}
                    >
                      Расходы
                    </span>
                  </div>

                  <div
                    onClick={() => sortReports('item')}
                    className={classNames(styles.docKm, 'col-auto col-xl-auto')}
                  >
                    <span
                      className={classNames(
                        styles.colorGray,
                        styles.tableSortCol,
                        'd-none d-xl-block'
                      )}
                    >
                      Дистанция
                    </span>
                  </div>
                </div>
              </div>
              {sortNewReports.map((item: any) => {
                const isEditReport =
                  item?.status !== 'sent' && item?.status !== 'closed'

                const getDate = (isoDateString: string) => {
                  const date = new Date(isoDateString)

                  const day = date.getDate().toString().padStart(2, '0')
                  const month = (date.getMonth() + 1)
                    .toString()
                    .padStart(2, '0')
                  const year = date.getFullYear()

                  const formattedDate = `${day}.${month}.${year}`
                  return formattedDate
                }

                const getTime = (date: string) => {
                  const newDate = new Date(date)
                  const hours = newDate.getHours().toString().padStart(2, '0')
                  const minutes = newDate
                    .getMinutes()
                    .toString()
                    .padStart(2, '0')

                  return `${hours}:${minutes}`
                }

                const date1 = new Date(item.date_from)
                const date2 = new Date(item.date_to)

                //@ts-ignore
                const differenceInMilliseconds = date2 - date1
                const differenceInHours = Math.floor(
                  differenceInMilliseconds / (1000 * 60 * 60)
                )
                const differenceInMinutes = Math.round(
                  (differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
                )
                return (
                  <>
                    <div
                      className={classNames(
                        styles.bgWhite,
                        'pl-2 pr-2 mb-3',
                        styles.border10,
                        styles.bxShadow,
                        styles.small2,
                        styles.docItem
                      )}
                    >
                      <div className={classNames(styles.row, 'row')}>
                        <div className="doc-number col-auto col-sm-3 col-md-3 col-lg-3 col-xl-2 small-3 pr-2 pt-1 pb-1">
                          <span
                            className={classNames(
                              'd-inline-block pl-2 pr-2 pt-0 pb-0 mb-1',
                              item.status === 'draft' ? styles.bgRed : '',
                              item.status === 'sent' ? styles.bgTurquoise : '',
                              item.status === 'closed' ? styles.bgDarkBlue : '',

                              styles.borderRadius5
                            )}
                          >
                            {getStatus(item.status)}
                          </span>
                          <br />
                          <b>{getDate(item.departure)}</b>
                          <br />
                          {getTime(item.departure)}
                        </div>
                        <div
                          className={classNames(
                            styles.docCar,
                            'col-8 col-sm-9 col-md-5 col-lg-5 col-xl-3 pr-2 pt-1 pb-1'
                          )}
                        >
                          <a
                            onClick={() => {
                              if (isEditReport) {
                                handleEditReport(item)
                              }
                            }}
                            className={classNames(
                              styles.avatarLink,
                              'd-flex align-items-center mb-1'
                            )}
                          >
                            <span className={styles.name}>
                              {item.user.name}
                            </span>
                          </a>
                          <a
                            onClick={() => {
                              dispatch(setIsVisibleModalCar(item?.car?.id))
                            }}
                            className="d-block"
                          >
                            {item?.car?.name}
                          </a>
                          {item?.trailer?.name && (
                            <div
                              onClick={() => {
                                dispatch(
                                  setIsVisibleModalCar(item?.trailer?.id)
                                )
                              }}
                              className="mt-0"
                            >
                              Прицеп: <a>{item?.trailer?.name}</a>
                            </div>
                          )}
                        </div>

                        {/* 
			<i class="icon icon-arrow arrow-right icon-10"></i>&nbsp;20.07.2024&nbsp;05:50<br>
			<i class="icon icon-arrow arrow-left icon-10"></i>&nbsp;22.07.2024&nbsp;15:21
		</div> */}
                        <div
                          className={classNames(
                            styles.docKm,
                            'col-auto col-sm-3 col-md-4 col-lg-4 col-xl-3 pr-2 pt-1 pb-1 small-3 nowrap'
                          )}
                        >
                          <div
                            className={classNames(
                              styles.colorLightGray,
                              'd-block d-xl-none'
                            )}
                          >
                            Граница
                          </div>
                          <i
                            className={classNames(
                              styles.icon,
                              styles.iconArrow,
                              styles.arrowRight,
                              styles.icon10
                            )}
                          ></i>
                          {item.crossing_border_there}
                          <br />
                          <i
                            className={classNames(
                              styles.icon,
                              styles.iconArrow,
                              styles.arrowLeft,
                              styles.icon10
                            )}
                          ></i>
                          {item.crossing_border_back}
                        </div>

                        <div
                          className={classNames(
                            styles.docMoney,
                            'col-auto col-sm-2 col-md-3 col-lg-3 col-xl-auto pt-1 pb-1'
                          )}
                        >
                          <div
                            className={classNames(
                              styles.small3,
                              styles.colorLightGray,
                              'd-block d-xl-none'
                            )}
                          >
                            Расходы
                          </div>
                          <a>
                            <b
                              className={classNames(
                                styles.colorRed,
                                styles.nowrap
                              )}
                            >
                              {item.all_expenses.toLocaleString('en')} р.
                            </b>
                          </a>
                        </div>

                        <div
                          className={classNames(
                            styles.docKm,
                            'col-auto col-sm-2 col-md-5 col-lg-5 col-xl-auto pt-1 pb-1'
                          )}
                        >
                          <div
                            className={classNames(
                              styles.small3,
                              styles.colorLightGray,
                              'd-block d-xl-none'
                            )}
                          >
                            Дистанция
                          </div>
                          <span className={styles.nowrap}>
                            {item.mileage_after
                              ? `${(item.mileage_after - item.mileage_before).toLocaleString('en', { minimumFractionDigits: 0, maximumFractionDigits: 0 })} км`
                              : 'В пути'}
                          </span>
                        </div>
                        {isEditReport ? (
                          <div
                            className="doc-buttons col pt-1 pb-1"
                            style={{ textAlign: 'right' }}
                          >
                            <EditButton
                              onClick={() => handleEditReport(item)}
                            />

                            <DeleteButton
                              onClick={() => handleDeleteReport(item)}
                            />
                          </div>
                        ) : (
                          <div
                            className={classNames('col pt-1 pb-1')}
                            style={{ textAlign: 'right' }}
                          >
                            <button
                              className={classNames(
                                styles.btn,
                                styles.btnBlueOutline,
                                styles.btnSmall,
                                styles.btnIcon,
                                styles.tooltip
                              )}
                            >
                              <div className={classNames(styles.tooltipText)}>
                                Вы уже не можете редактировать этот отчёт
                              </div>
                              <i
                                className={classNames(
                                  styles.icon,
                                  styles.iconLockRed,
                                  styles.icon20
                                )}
                              ></i>
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )
              })}
            </div>
          </table>
        </div>
      )}
    </>
  )
}

export default ReportsResult
