import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { URL } from '../../constants/url'
import { PATH } from '../../constants/path'
import axiosInstance from '../../utils/axiosInstance'
import { AxiosError, AxiosResponse } from 'axios'
import { toast } from 'react-toastify'
import { getRole } from '../../utils/getRole'
import { MyKnownError } from '../types/common'

export interface AuthState {
  errorMessage: string | null
  isVisibleModalChangeEmail: boolean
}

const initialState: AuthState = {
  errorMessage: null,
  isVisibleModalChangeEmail: false,
}

type FetchAuth = { login: string; password: string; remember: boolean }
export const fetchAuth = createAsyncThunk(
  'auth/fetchAuth',
  async (
    { login, password, remember }: FetchAuth,
    { dispatch, rejectWithValue }
  ) => {
    const data = {
      email: login,
      password: password,
      device_name: 'My Phone',
      remember: remember,
    }

    try {
      const response: AxiosResponse = await axiosInstance.post(
        `/${URL.auth}/login`,
        data
      )

      if (response.statusText === 'OK') {
        if (remember) {
          document.cookie = `auth=${response.data.token};max-age=2592000;path=/`
        } else {
          document.cookie = `auth=${response.data.token}`
        }

        await dispatch(fetchAuthUser())

        return response
      }
      throw new Error()
    } catch (error) {
      if (error instanceof AxiosError) {
        console.error(error)
        return rejectWithValue(error.response?.data)
      } else {
        console.error('An unexpected error occurred:', error)
        return rejectWithValue('An unexpected error occurred')
      }
    }
  }
)

export const fetchAuthUser = createAsyncThunk(
  'auth/fetchAuthUser',
  async () => {
    try {
      const response: AxiosResponse = await axiosInstance.get(
        `/${URL.auth}/user`
      )

      if (response.statusText === 'OK') {
        return response
      }
      throw new Error()
    } catch (error) {
      console.error(error)
      throw error
    }
  }
)

export const fetchChangeEmail = createAsyncThunk(
  'auth/fetchChangeEmail',
  async (email: string, thunkAPI) => {
    try {
      const response: AxiosResponse = await axiosInstance.post(
        `/${URL.auth}/change-email`,
        { email }
      )
      if (response.statusText === 'OK') {
        return response
      }
      throw new Error()
    } catch (error) {
      const typedError = error as MyKnownError

      return thunkAPI.rejectWithValue(
        typedError?.response?.data?.message || 'Ошибка при отправке данных'
      )
    }
  }
)

export const fetchForgotPassword = createAsyncThunk(
  'auth/fetchForgotPassword',
  async (email: string, thunkAPI) => {
    try {
      const response: AxiosResponse = await axiosInstance.post(
        `/${URL.auth}/forgot-password`,
        { email }
      )
      if (response.statusText === 'OK') {
        return response
      }
      throw new Error()
    } catch (error) {
      const typedError = error as MyKnownError

      return thunkAPI.rejectWithValue(
        typedError?.response?.data?.message || 'Ошибка при отправке данных'
      )
    }
  }
)

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearErrorMessage: (state) => ({
      ...state,
      errorMessage: null,
    }),
    logout: (state) => {
      document.cookie = 'auth=; Max-Age=-1;'
      return state
    },
    setIsVisibleModalChangeEmail: (state, action: { payload: boolean }) => ({
      ...state,
      isVisibleModalChangeEmail: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAuth.pending, (state) => {
        return state
      })
      .addCase(fetchAuth.fulfilled, (state, action) => {
        const { isDriver } = getRole()
        if (isDriver) {
          window.location.href = PATH.MY_REPORTS
        } else {
        window.location.href = PATH.CARS
        }
        return state
      })
      .addCase(fetchAuth.rejected, (state, action) => {
        return {
          ...state,
          errorMessage: 'The provided credentials are incorrect.',
        }
      })
      .addCase(fetchAuthUser.pending, (state, action) => {
        return state
      })
      .addCase(fetchAuthUser.fulfilled, (state, action) => {
        localStorage.setItem('userInfo', JSON.stringify(action.payload.data))
        return state
      })
      .addCase(fetchAuthUser.rejected, (state, action) => {
        return state
      })
      .addCase(fetchChangeEmail.pending, (state, action) => {
        return state
      })
      .addCase(fetchChangeEmail.fulfilled, (state, action) => {
        toast.success(action.payload.data.message)

        return state
      })
      .addCase(fetchChangeEmail.rejected, (state, action) => {
        toast.error(action.payload as string)

        return state
      })
      .addCase(fetchForgotPassword.pending, (state, action) => {
        return state
      })
      .addCase(fetchForgotPassword.fulfilled, (state, action) => {
        toast.success(action.payload.data.message)

        return state
      })
      .addCase(fetchForgotPassword.rejected, (state, action) => {
        toast.error(action.payload as string)

        return state
      })
  },
})

export const { logout, setIsVisibleModalChangeEmail, clearErrorMessage } =
  authSlice.actions

export default authSlice.reducer
