import { RootState } from '../store'

export const usersSelector = (state: RootState) => {
  const { users } = state

  return users
}

export const driversAndAssistantsSelector = (state: RootState) => {
  const { users } = state

  return users.users.filter(
    (user) =>
      user.role === 'driver' ||
      user.role === 'owner'
  )
}
