import { useState } from 'react'
import styles from './HeaderMain.module.css'
import classNames from 'classnames'
import { useOutsideClick } from '../../hooks/useOutsideClick'

import { useLocation } from 'react-router-dom'
import { PATH } from '../../constants/path'
import {
  Company,
  setCurrentCompany,
  setIsVisibleModalCompany,
} from '../../redux/reducers/company'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../redux/store'
import { companySelector } from '../../redux/selectors/company-selector'
import { setCurrentCar, setIsVisibleModalCar } from '../../redux/reducers/cars'
import {
  setCurrentUser,
  setCurrentUserByRequest,
  setIsVisibleModalUser,
} from '../../redux/reducers/users'
import {
  setCurrentReport,
  setCurrentReportId,
  setIsVisibleModalReport,
} from '../../redux/reducers/reports'
import { setCurrentService } from '../../redux/reducers/services'
import { setCurrentNote } from '../../redux/reducers/notes'
import {
  setCurrentClient,
  setCurrentClientByRequest,
  setIsVisibleModalClient,
} from '../../redux/reducers/clients'
import { clearPhones } from '../../redux/reducers/phones'
import {
  setCurrentContract,
  setIsVisibleModalContract,
} from '../../redux/reducers/documents/contracts'
import {
  setCurrentPart,
  setIsVisibleModalPart,
} from '../../redux/reducers/parts'

const TITLE = {
  [PATH.DOCUMENTS]: 'Документы',
  [PATH.REPORTS]: 'Отчеты',
  [PATH.MY_REPORTS]: 'Мои отчёты',
  [PATH.EMPLOYEES]: 'Сотрудники',
  [PATH.CARS]: 'Транспорт',
  [PATH.CLIENTS]: 'Клиенты',
  [PATH.PROFILE]: 'Профиль',
  [PATH.SETTINGS]: 'Настройки',
  [PATH.PARTS]: 'Запчасти',
}

function HeaderMain() {
  const dispatch = useDispatch<AppDispatch>()
  const location = useLocation()

  const isVisibleAddButton =
    location.pathname !== PATH.SETTINGS && location.pathname !== PATH.REPORTS

  const title = TITLE[location.pathname as keyof typeof TITLE]

  const handleClick = () => {
    switch (location.pathname) {
      case PATH.CARS:
        dispatch(setCurrentCar(null))
        dispatch(setCurrentService(null))
        dispatch(setCurrentNote([]))
        dispatch(setIsVisibleModalCar(true))
        break
      case PATH.EMPLOYEES:
        dispatch(setCurrentUser(null))
        dispatch(setCurrentUserByRequest(null))
        dispatch(setIsVisibleModalUser(true))
        break
      case PATH.MY_REPORTS:
        dispatch(setCurrentReport(null))
        dispatch(setCurrentReportId(null))
        dispatch(setIsVisibleModalReport(true))
        break
      case PATH.CLIENTS:
        dispatch(setCurrentClient(null))
        dispatch(setCurrentClientByRequest(null))
        dispatch(setIsVisibleModalClient(true))
        dispatch(clearPhones())
        break
      case PATH.DOCUMENTS:
        dispatch(setCurrentContract(null))
        dispatch(setIsVisibleModalContract(true))
        break
      case PATH.PARTS:
        dispatch(setCurrentPart(null))
        dispatch(setIsVisibleModalPart(true))
        break
      default:
        break
    }
  }

  return (
    <div className="row align-items-center mb-3">
      <div className="col-7 col-sm-7 mb-3">
        <h2 className={`mb-0 ${styles.h2}`}>{title}</h2>
      </div>
      {(isVisibleAddButton || location.pathname === PATH.MY_REPORTS) && (
        <div className="col-5 col-sm-5 mb-3" style={{ textAlign: 'right' }}>
          <a
            onClick={handleClick}
            className={`${styles.btn} ${styles.btnSmall}`}
          >
            {location.pathname === PATH.MY_REPORTS
              ? 'Новый отчёт'
              : '+ Добавить'}
          </a>
        </div>
      )}
    </div>
  )
}

export default HeaderMain
