import classNames from 'classnames'
import HeaderMain from '../../components/header-main/HeaderMain'
import ReportsCalendar from './reports-calendar/ReportsCalendar'
import ReportsFilter from './reports-filter/ReportsFilter'
import ReportsResult from './reports-result/ReportsResult'
import { Range } from 'react-date-range'
import styles from './Reports.module.css'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchReports } from '../../redux/reducers/reports'
import { AppDispatch } from '../../redux/store'
import { getRole } from '../../utils/getRole'
import { companySelector } from '../../redux/selectors/company-selector'
import {
  fetchCars,
  // fetchCarsForAdmins
} from '../../redux/reducers/cars'
import { fetchUsers, fetchUsersForAdmin } from '../../redux/reducers/users'
import { reportFilterSelector } from '../../redux/selectors/report-filter-selector'

const { isOwner, isAdmin } = getRole()

function Reports() {
  const dispatch = useDispatch<AppDispatch>()
  const { calendarRange } = useSelector(reportFilterSelector)
  const { selectedTrucks, selectedDrivers } = useSelector(reportFilterSelector)

  const { currentCompany } = useSelector(companySelector)

  // this filter need for sort reports (now don't working)
  const [orderBy, setOrderBy] = useState('id')
  const [orderDirection, setOrderDirection] = useState('desc')

  const getCars = selectedTrucks?.map((item) => item.id)
  const getEmployees = selectedDrivers?.map((item) => item.id)
  const [isVisibleForm, setIsVisibleForm] = useState(true)

  useEffect(() => {
    // dispatch(
    //   fetchReports({
    //     dateFrom: calendarRange[0].startDate,
    //     dateTo: calendarRange[0].endDate,
    //     orderBy,
    //     orderDirection,
    //   })
    // )

    dispatch(fetchCars())
    dispatch(fetchUsers())
  }, [])

  const getReports = () => {
    dispatch(
      fetchReports({
        cars: getCars,
        employees: getEmployees,
        dateFrom: calendarRange[0].startDate,
        dateTo: calendarRange[0].endDate,
        orderBy,
        orderDirection,
      })
    )
  }

  useEffect(() => {
    dispatch(
      fetchReports({
        dateFrom: calendarRange[0].startDate,
        dateTo: calendarRange[0].endDate,
        cars: getCars,
        employees: getEmployees,
      })
    )
  }, [calendarRange])

  return (
    <div className={styles.accountContainer}>
      <div className={styles.accountWideContent}>
        <div className={styles.accountWideContentContainer}>
          <HeaderMain />
          <div className="row" style={{ justifyContent: 'space-evenly' }}>
            <ReportsCalendar
              calendarRange={calendarRange}
              isVisibleForm={isVisibleForm}
              setIsVisibleForm={setIsVisibleForm}
            />
            <div
              className={classNames(
                'col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 account-right-col',
                styles.accountRightCol
              )}
            >
              <ReportsFilter
                selectedTrucks={selectedTrucks}
                selectedDrivers={selectedDrivers}
                getReports={getReports}
                isVisibleForm={isVisibleForm}
              />
              <ReportsResult
                dateFrom={calendarRange[0].startDate}
                dateTo={calendarRange[0].endDate}
                getCars={getCars}
                getEmployees={getEmployees}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Reports
